var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:_vm.$style.cartsList,attrs:{"headers":_vm.filtered_cols,"items":_vm.items,"hide-default-footer":"","options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.cart_history_loading},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.external_number)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.external_number)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.external_number)+" ")]),(item.id && item.external_number)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.open_in_new_tab(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Открыть в новой вкладке")])]):_vm._e()],1)])]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.discount || '-')+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.price))+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.organization)+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("date")(item.updated_at))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.repeat_cart(item)}}},on),[_c('v-icon',[_vm._v(_vm._s(item.is_external_api ? 'mdi-cart' : 'mdi-sync'))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.is_external_api ? 'Оформить' : 'Повторить')+" ")])],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)}),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":function($event){return _vm.cols_dialog_change(false)},"cancel_dialog":function($event){return _vm.cols_dialog_change(false)},"close":function($event){return _vm.cols_dialog_change(false)},"default_limit":_vm.default_limit},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }